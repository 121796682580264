<template>
  <h1>Secret!</h1>
</template>

<script>
export default {
  name: "SecretView"
}
</script>

<style scoped>

</style>